<template>
  <div class="quote-marketplace-sidebar">
    <div class="quote-marketplace-sidebar--content">
      <v-form ref="form">
        <v-switch
          id="quote-marketplace-enabled"
          v-model="charterUpQuote"
          label="Marketplace"
          class="live-switch"
          hide-details
        />
      </v-form>
    </div>
    <div class="cr-sidebar-dialog--button-spacer" />
    <div>
      <CRButton
        id="quote-marketplace-save-btn"
        :loading="submitting"
        class="quote-marketplace-sidebar--action-btn"
        color="primary"
        @click="submit"
      >
        Save
      </CRButton>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { authComputed } from '@/state/helpers'
import { EventBus } from '@/utils/event-bus'

export default {
  props: {
    quote: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      charterUpQuote: false,
    }
  },
  computed: {
    ...authComputed,
  },
  async mounted() {
    this.charterUpQuote = this.quote.charterUpQuote
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    async submit() {
      const payload = {
        quoteId: this.quote.quoteId,
        charterUpQuote: this.charterUpQuote,
      }
      try {
        const res = await this.$store.dispatch(
          'quotes/updateQuoteMarketplace',
          payload
        )
        EventBus.$emit('refreshQuote')
      } catch (e) {
        this.showAlert({
          type: 'error',
          message: 'Error creating vanity url.',
        })
      }
      this.close()
    },
  },
}
</script>
<style lang="scss" scoped>
.quote-marketplace-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 0;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0;
  }
}

::v-deep .accent--text {
  color: $blue !important;
}
</style>
